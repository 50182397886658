import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="container py-8">
        <h1 className="h1">Neem contact op met Thomas' Webdesign</h1>
        <p className="paragraph hidden sm:block">
          Vul het onderstaande formulier in of gebruik de contactgevevens aan de
          rechterkant.
        </p>
        <p className="paragraph block sm:hidden">
          Vul het formulier in of gebruik de onderstaande contactgevevens.
        </p>
        <section className="row sm:mt-2 mt-4">
          <div className="column sm:w-1/2">
            <form action="https://formspree.io/xnqabnne" method="POST">
              <input type="text" name="_gotcha" style={{ display: "none" }} />
              <label className="mb-4 block">
                <span className="label label--optional">Naam</span>
                <input
                  className="form-input border border-gray-300 rounded block w-full"
                  name="name"
                  type="text"
                  autoFocus
                />
              </label>
              <label className="mb-4 block">
                <span className="label label--optional">Telefoonnummer</span>
                <input
                  className="form-input border border-gray-300 rounded block w-full"
                  name="phone"
                  type="tel"
                />
              </label>
              <label className="mb-4 block">
                <span className="label">E-mailadres</span>
                <input
                  className="form-input border border-gray-300 rounded block w-full"
                  name="_replyto"
                  type="email"
                  required
                />
              </label>
              <label className="mb-4 block">
                <span className="label">Bericht</span>
                <textarea
                  className="form-textarea border border-gray-300 rounded block w-full"
                  name="message"
                  rows="13"
                  required
                />
              </label>
              <footer className="mb-4">
                <button type="submit" className="button">
                  Verstuur bericht
                </button>
              </footer>
            </form>
          </div>
          <div className="column sm:w-1/2 order-first sm:order-last">
            <div className="rounded-sm h-48 bg-gray-300 p-4 text-center flex flex-col justify-center text-gray-700">
              <h1 className="h3">Thomas' Webdesign</h1>
              <p className="text-gray-700">KvK: 76138437</p>
            </div>
            <iframe
              className="rounded-sm mt-4 hidden sm:block"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.1579243772785!2d6.888140515399015!3d52.22234856599804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8147510042cfd%3A0x6ad8eb288733dbeb!2sEnschede!5e0!3m2!1snl!2snl!4v1572291606878!5m2!1snl!2snl"
              width="100%"
              height="250"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              title="Google Maps"
            ></iframe>
          </div>
        </section>
      </div>
    </Layout>
  )
}
